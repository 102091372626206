import React from 'react';
import styled from '@emotion/styled';

import { web_max_width } from '@common/styles';
import { ZigzagTopNavigationItemIcon } from '@common/top-navigation';

import { BaseNavigation } from '../navigation-bar/BaseNavigation';
import { useRouter } from 'next/router';

export const HEADER_HEIGHT = 44;

declare const window: Window & {
  __TOSS_API__?: {
    closeWebView: () => void;
  };
};

interface Props {
  main_domain: string;
  show_search_button?: boolean;
  title?: string;
}

export const StoreHomeHeader: React.FC<Props> = ({ main_domain, show_search_button = true, title }) => {
  const renderSearchButton = () => <ZigzagTopNavigationItemIcon.SearchIcon href={`/${main_domain}/search`} />;

  const handleHomeClick = () => {
    if (window.__TOSS_API__) {
      window.__TOSS_API__.closeWebView();
      return true;
    }
  };

  const router = useRouter();
  const pathname = router.pathname;
  const showHomeIcon = !pathname.includes('/category/');

  return (
    <StyledBaseNavigation
      title={title}
      right={
        <HeaderRightButtons>
          {showHomeIcon && <ZigzagTopNavigationItemIcon.HomeIcon href='/' onClick={handleHomeClick} />}
          {show_search_button && renderSearchButton()}
          <ZigzagTopNavigationItemIcon.CartIcon href='/cart' />
        </HeaderRightButtons>
      }
    />
  );
};

const StyledBaseNavigation = styled(BaseNavigation)`
  position: sticky;
  top: 0;
  width: 100%;
  max-width: ${web_max_width}px;
  z-index: 205;
`;

const IconWrapper = styled.div`
  padding: 6px;
  width: 44px;
  height: 44px;
`;

const HeaderRightButtons = styled.span`
  display: flex;
  align-items: center;
`;
